<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          cols="1"
          md="2"
          class="text-center"
        />
        <b-col
          cols="6"
          md="8"
          class="mb-2"
        >
          <div class="cardHie1">
            <h3
              style="color: rgb(115, 103, 240)"
              class="mb-2"
            >
              เพิ่มคำขอการดูแฟ้มข้อมูล
            </h3>
            <b-row>
              <b-col cols="12">
                <hr>
                <h4
                  style="color: rgb(0 0 0)"
                  class="mt-1 ml-1"
                >ผู้ขอข้อมูล
                  <b-badge
                    v-if="title != '' || first_name != '' || last_name != ''"
                    variant="success"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-25"
                    />
                  </b-badge>
                </h4>
                <hr>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="ค้นหาผู้ใช้งาน"
                  label-for="mc-first-name"
                >
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-modal.modal-dark
                    size="sm"
                    variant="outline-primary"
                    @click="getUserhie()"
                  >
                    รายชื่อ User ในระบบ
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col md="4" />
              <b-col md="4" />
              <b-col md="2">
                <b-form-group
                  label="คำนำหน้า"
                  label-for="mc-first-name"
                >
                  <b-form-input
                    id="mc-first-name"
                    v-model="title"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="ชื่อ"
                  label-for="mc-first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="mc-first-name"
                      v-model="first_name"
                      :state="errors.length > 0 ? false:null"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="นามสกุล"
                  label-for="mc-last-name"
                >
                  <b-form-input
                    id="mc-last-name"
                    v-model="last_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" />
              <br>
              <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////// -->
              <b-col cols="12">
                <hr>
                <h4
                  style="color: rgb(0 0 0)"
                  class="mt-1 ml-1"
                >ข้อมูลคนไข้ที่ต้องการขอ
                  <b-badge
                    v-if="itemshie.length > 0"
                    variant="success"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-25"
                    />
                  </b-badge>
                </h4>
                <hr>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="ค้นหาคนไข้"
                  label-for="mc-first-name"
                >
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    v-b-modal.modal-primary
                    size="sm"
                    variant="outline-primary"
                    @click="getPatient_hie()"
                  >
                    ค้นหารายชื่อคนไข้ในระบบ
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col md="5" />
              <b-col md="5" />
              <b-col md="8">
                <b-form-group
                  label="เหตุผลที่ต้องการขอ"
                  label-for="mc-city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="patient reason"
                    rules="required"
                  >
                    <b-form-input
                      id="mc-city"
                      v-model="patient_reason"
                      placeholder="เหตุผลที่ต้องการขอ"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-for="Email"
                  label="ขอเอกสารไปดูที่"
                >
                  <!-- <div class="form-label-group">
                    <b-form-input
                      id="mc-email"
                      v-model="request_to"
                      disabled
                      placeholder="ขอเอกสารไปดูที่"
                    />
                  </div> -->
                  <b-form-select
                    v-model="request_to"
                    :options="optionsRequest_to"
                  />
                </b-form-group>
              </b-col>
              <hr>
              <!-- submit and reset -->
              <b-col md="12" />
              <br>
              <b-col md="12" />
              <b-table-lite
                hover
                :fields="fieldPatients"
                :items="itemshie"
              >
                <template #cell(hn)="data">
                  <b-badge
                    variant="light-primary"
                    class="badge-glow"
                  >
                    <span>{{ data.item.hn }}</span>
                  </b-badge>
                </template>
                <template #cell(fullname)="data">
                  <b-badge
                    variant="light-dark"
                    class="badge-glow"
                  >
                    <span>{{ data.item.Title }} {{ data.item.FirstName }} {{ data.item.LastName }}</span>
                  </b-badge>
                </template>
                <template #cell(delete)="row">
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="gradient-danger"
                    class="btn-icon rounded-circle"
                    @click="Minusinfo(row.item, row.index, $event.target)"
                  >
                    <feather-icon icon="UserMinusIcon" />
                  </b-button>
                </template>
              </b-table-lite>
              <b-col cols="12" />
              <b-col cols="12" />
              <b-col cols="12" />
              <b-col />
              <b-col class="text-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click.prevent="validationForm"
                >
                  บันทึกข้อมูล
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="success"
                >
                  Reset
                </b-button> -->
              </b-col>
              <b-col />
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="text-center"
        />
      </b-row>
    </validation-observer>
    <b-modal
      id="modal-dark"
      ref="my-modal"
      hide-footer
      ok-only
      ok-variant="danger"
      ok-title="CLOSE"
      modal-class="modal-dark"
      size="xl"
      centered
      title="HIE USER"
    >
      <b-card>
        <b-row>
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <!-- <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group> -->
          </b-col>
          <b-col
            md="4"
            sm="8"
            class="my-1"
          >
            <b-form-group
              label="Sort"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      -- none --
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="my-1"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            sm="12"
          >
            <b-table
              hover
              responsive
              :sticky-header="stickyHeader"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              selectable
              :select-mode="selectMode"
              :head-variant="headVariant"
              @filtered="onFiltered"
              @row-selected="onRowSelected"
            >
              <!-- Status -->
              <template #cell(type)="data">
                <b-badge
                  class="badge-glow d-block"
                  :variant="type[1][data.value] "
                >
                  {{ type[0][data.value] }}
                </b-badge>
              </template>
              <template #cell(username)="data">
                <b-badge
                  variant="light-dark"
                >
                  <feather-icon
                    icon="UserIcon"
                    class="mr-25"
                  />
                  <span> {{ data.item.username }}</span>
                </b-badge>
              </template>
              <template #cell(fullName)="data">
                <b-badge
                  variant="light-dark"
                >
                  <span> {{ data.item.title }} {{ data.item.fName }} {{ data.item.lName }}</span>
                </b-badge>
              </template>
              <template #cell(email)="data">
                <b-badge
                  variant="light-dark"
                >
                  <span> {{ data.item.email }}</span>
                </b-badge>
              </template>
              <template #cell(phone)="data">
                <b-badge
                  variant="light-dark"
                >
                  <span> {{ data.item.phone }}</span>
                </b-badge>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal
      id="modal-primary"
      ok-only
      hide-footer
      ok-variant="danger"
      ok-title="CLOSE"
      modal-class="modal-primary"
      centered
      title="HIE PATIENT"
    >
      <b-card>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="ค้นหาคนไข้ด้วย HN"
              label-for="mc-first-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="codeHnSri"
                  type="number"
                  placeholder="HN.(ศรีพัฒน์)"
                  @keydown.enter="codeHNsri"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="codeHNsri"
                  >
                    ค้นหา
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <br>
              <b-form-group
                label="ค้นหาคนไข้ด้วย ID CARD"
                label-for="mc-first-name"
              >
                <b-input-group>
                  <b-form-input
                    v-model="IDCard"
                    type="number"
                    placeholder="ID CARD"
                    @keydown.enter="idCard"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="idCard"
                    >
                      ค้นหา
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <br>
                <br>
                <div
                  v-show="fullName !== 'ผู้ป่วยดังกล่าวไม่อยู่ในระบบโรงพยาบาล' && fullName != ''"
                  style="text-align: center;border: 1px solid;padding: 10px;box-shadow: 5px 10px #615d86;"
                >
                  <h4>{{ fullName }}</h4>
                  <b-button
                    v-if="fullName !== 'ผู้ป่วยดังกล่าวไม่อยู่ในระบบโรงพยาบาล' && fullName != ''"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="pushPatientDetail"
                  >
                    +เพิ่ม
                  </b-button>
                </div>
              </b-form-group>
            </b-form-group></b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  // props: ['hn', 'name', 'txn', 'labdetails'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BTable,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      selectMode: 'single',
      headVariant: 'light',
      codeDocSri: '',
      InfoDoc: [],
      title: '',
      first_name: '',
      last_name: '',
      fullNameDoc: '',
      codeDoc: '',
      docLicense: '',
      alertStatus: false,
      request_to: null,
      value: '',
      emailValue: '',
      name: '',
      required,
      email,
      patient_title: '',
      patient_first_name: '',
      patient_last_name: '',
      patient_hn: '',
      patient_reason: 'เพื่อการรักษา',
      patient_info_requested: '',
      setRequestStatus: null,
      itemshie: [],
      nextTodoId: 2,
      fields: [
        {
          key: 'type', label: 'TYPE', sortable: true,
        },
        {
          key: 'username', label: 'USERNAME',
        },
        'fullName',
        {
          key: 'phone', label: 'PHONE',
        },
        {
          key: 'email', label: 'Email',
        },
      ],
      // fields: [
      //   {
      //     key: 'type', label: 'ประเภท', sortable: true,
      //   },
      //   {
      //     key: 'username', label: 'USERNAME',
      //   },
      //   {
      //     key: 'title', label: 'คำนำหน้า', sortable: true,
      //   },
      //   {
      //     key: 'fName', label: 'ชื่อ', sortable: true,
      //   },
      //   {
      //     key: 'lName', label: 'สกุล',
      //   },
      //   {
      //     key: 'phone', label: 'เบอร์โทร',
      //   },
      //   {
      //     key: 'email', label: 'Email',
      //   },
      // ],
      fieldPatients: [
        {
          key: 'hn', label: 'hn',
        },
        'fullname',
        'delete',
      ],
      itemsHIE: [],
      type: [{
        5: 'ผู้ป่วย', 1: 'แพทย์', 3: 'พนักงานสวนดอก', 2: 'พนักงานศูนย์ศรีพัฒน์', 4: 'ญาติผู้ป่วย', 6: 'บริษัทประกัน', 9: 'ผู้ดูแลระบบ',
      },
      {
        5: 'light-primary', 1: 'light-success', 6: 'light-dark', 2: 'light-warning', 3: 'light-info', 9: 'light-danger', 4: 'light-secondary',
      }],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      selected: [],
      selected1: [],
      id_Bset: '',
      id: '',
      idList: [],
      codeHnSri: '',
      getPatientDetail: [],
      items: [],
      fullName: '',
      itemsHN: [],
      keepUsername: '',
      stickyHeader: true,
      optionsRequest_to: [
        { value: null, text: 'กรุณาเลือก' },
        { value: 'โรงพยาบาลมหาราชนครเชียงใหม่', text: 'โรงพยาบาลมหาราชนครเชียงใหม่' },
        { value: 'ข้างนอกโรงพยาบาล', text: 'ข้างนอกโรงพยาบาล' },
        { value: 'อื่น ๆ', text: 'อื่น ๆ' },
      ],
      selectedRequest_to: null,
      iDCard: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.getDocInfo()
    // this.getPatient_hie()
    this.keepUsername = JSON.parse(localStorage.getItem('userData')).username
  },
  methods: {
    // getPatient_hie() {
    //   // console.log('setTimeout(() => {this.getPatient_hie()}, 1000)')
    //   setTimeout(() => {
    //     this.getPatient_hie()
    //   }, 60000)
    // },
    async getUserhie() {
      await this.$http({
        url: 'api/User/getUser/ALL',
        method: 'get',
        data: {
        },
      }).then(response => {
        // console.log(response.data)
        this.items = response.data
      })
      await this.totalRowsHIE()
    },
    totalRowsHIE() {
      this.totalRows = this.items.length
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.setRequest()
          // alert('form submitted!')
        }
      })
    },
    async setRequest() {
      if (this.selected[0].username === '6511002') {
        let i = 0
        while (i < this.idList.length) {
          this.$http({
            url: 'api/PhysicalTherapy/NewRequestPhysicalTherapy',
            method: 'post',
            headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
            data: {
              IDCard: this.idList[i],
              ReqStatus: 0,
              ReqNote: this.patient_reason,
            },
          })
          i += 1
        }
        await this.alertRequestStatus()
      } else {
        await this.$http({
        // url: 'https://localhost:5001/api/hospital-information-exchange/setRequest',
          url: '/api/HIE/setRequest',
          method: 'post',
          data: {
            uid: this.id_Bset,
            reason: `เหตุผล ${this.patient_reason}, ขอเอกสารไปดูที่ ${this.request_to}`,
            createby: this.keepUsername,
            hns: this.itemsHN,
          },
        }).then(response => {
          this.setRequestStatus = response.data
        })
        if (this.request_to === 'ข้างนอกโรงพยาบาล') {
          await this.alertRequestStatusRequest_to()
        } else {
          await this.alertRequestStatus()
        }
      }
    },
    alertRequestStatus() {
      if (this.setRequestStatus === true) {
        this.success()
      } else {
        this.error()
      }
    },
    alertRequestStatusRequest_to() {
      if (this.setRequestStatus === true) {
        this.successReq()
      } else {
        this.error()
      }
    },
    async successReq() {
      await this.$swal({
        title: 'ส่งคำขอสำเร็จ',
        text: 'คำขอถูกส่งเรียบร้อยแล้ว',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      console.log('successReq')
      // await this.$router.resolve({ name: 'RequestHistory' })
      // await this.$router.push({ path: '/hie/transferFile' })
      console.log('itemsHN:', this.itemsHN)
      await this.$router.push({ name: 'transferFile', params: { hn: this.itemsHN } })
    },
    async success() {
      await this.$swal({
        title: 'ส่งคำขอสำเร็จ',
        text: 'คำขอถูกส่งเรียบร้อยแล้ว',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      await this.$router.go()
    },
    error() {
      this.$swal({
        title: 'ส่งคำขอไม่สำเร็จ',
        text: ' กรุณาตรวจสอบข้อมูลหรือส่งคำขอใหม่',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async codeSearch() {
      await this.docName()
      if (this.alertStatus === true) {
        this.success()
      } else {
        this.error()
      }
    },
    async idCard() {
      await this.$http({
        url: `/api/HIE/GetPatientInfo/-/-/${this.IDCard}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.getIdCardDetail = response.data
        console.log('Idcard', response.data)
      })
      await this.keepIDcardDetail()
    },
    async codeHNsri() {
      const HN = this.codeHnSri.padStart(7, '0')
      await this.$http({
        url: `/api/HIE/SearchPatientByHN/${HN}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.getPatientDetail = response.data
      })
      await this.keepPatientDetail()
      // await this.pushPatientDetail()
    },
    keepIDcardDetail() {
      if (this.getIdCardDetail.length !== 0) {
        this.fullName = `HN: ${this.getIdCardDetail[0].hn} || ${this.getIdCardDetail[0].title} ${this.getIdCardDetail[0].fName} ${this.getIdCardDetail[0].lName} |`
      } else {
        this.fullName = 'ไม่พบ ID CARD ผู้ป่วยดังกล่าว'
      }
    },
    keepPatientDetail() {
      if (this.getPatientDetail === 'ผู้ป่วยดังกล่าวไม่อยู่ในระบบโรงพยาบาล') {
        this.fullName = this.getPatientDetail
      } else {
        this.fullName = `HN: ${this.getPatientDetail[0].hn} || ${this.getPatientDetail[0].title} ${this.getPatientDetail[0].fName} ${this.getPatientDetail[0].lName} |`
      }
    },
    async pushPatientDetail() {
      if (this.codeHnSri !== '') {
        await this.itemshie.push({
          hn: this.getPatientDetail[0].hn, Title: this.getPatientDetail[0].title, FirstName: this.getPatientDetail[0].fName, LastName: this.getPatientDetail[0].lName, idCard: this.getPatientDetail[0].id,
        })
        await this.itemsHN.push(this.getPatientDetail[0].hn)
        await this.idList.push(this.getPatientDetail[0].id)
      } else {
        await this.itemshie.push({
          hn: this.getIdCardDetail[0].hn, Title: this.getIdCardDetail[0].title, FirstName: this.getIdCardDetail[0].fName, LastName: this.getIdCardDetail[0].lName, idCard: this.getIdCardDetail[0].id,
        })
        await this.itemsHN.push(this.getIdCardDetail[0].hn)
        await this.idList.push(this.getIdCardDetail[0].id)
      }
      // console.log('this.itemshie:', this.itemshie)
      await this.alertpushPatientDetail()
    },
    async alertpushPatientDetail() {
      await this.$swal({
        title: 'เพิ่มคนไข้สำเร็จ',
        text: `${this.fullName}`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      this.fullName = ''
      this.codeHnSri = ''
      this.IDCard = ''
    },
    // successDoc_Search() {
    //   this.$swal({
    //     title: `${this.codeDoc}`,
    //     text: `${this.fullNameDoc}`,
    //     icon: 'success',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //     },
    //     buttonsStyling: false,
    //   })
    // },
    // errorDoc_Search() {
    //   this.$swal({
    //     title: 'ไม่พบข้อมูล',
    //     text: ' กรุณาตรวจสอบหรือใส่ข้อมูลใหม่',
    //     icon: 'error',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //     },
    //     buttonsStyling: false,
    //   })
    // },
    getDocInfo() {
      this.$http({
        url: 'api/Doctor/DoctorsAndClinic',
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(response => {
        this.InfoDoc = response.data.allDoc
      })
      setTimeout(() => {
        this.getDocInfo()
        this.getPatient_hie()
      }, 100)
    },
    docName() {
      let index = 0
      for (index; index < this.InfoDoc.length; index += 1) {
        if (this.InfoDoc[index].doctorCode === this.codeDocSri.toUpperCase()) {
          // // console.log(this.InfoDoc[index])
          this.fullNameDoc = `${this.InfoDoc[index].title} ${this.InfoDoc[index].fName} ${this.InfoDoc[index].lName}`
          this.codeDoc = `${this.InfoDoc[index].doctorCode}`
          this.docLicense = `${this.InfoDoc[index].docLicense}`
          this.first_name = `${this.InfoDoc[index].fName}`
          this.last_name = `${this.InfoDoc[index].lName}`
          this.title = `${this.InfoDoc[index].title}`
          this.alertStatus = true
          break
        // eslint-disable-next-line no-empty
        } else {
          this.alertStatus = false
        }
      }
      // console.log(this.fullNameDoc)
      this.$swal({
        title: `${this.codeDoc}`,
        text: `${this.fullNameDoc}`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      this.selected1 = item
      // console.log(item)
    },
    async Minusinfo(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      await this.deleteobj(index)
    },
    deleteobj(index) {
      // delete this.itemshie[index]
      // console.log('this.itemshie:', this.itemshie, index)
      if (index === 0) {
        this.itemshie.shift()
        this.itemsHN.shift()
      } else {
        this.itemshie.splice(index, index)
        this.itemsHN.splice(index, index)
      }
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onRowSelected(items) {
      this.selected = items
      // console.log(items[0].id, items[0].tile, items[0].fName)
      this.$refs['my-modal'].hide()
      await this.setName()
    },
    setName() {
      this.title = this.selected[0].title
      this.first_name = this.selected[0].fName
      this.last_name = this.selected[0].lName
      this.id_Bset = this.selected[0].id
      this.makeToast('success')
    },
    makeToast(variant = null) {
      this.$bvToast.toast(`${this.title} ${this.first_name} ${this.last_name}`, {
        title: 'เลือก USER สำเร็จ',
        variant,
        solid: true,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style>
.btn-secondary {
    border-color: #006ef5 !important;
    background-color: #0073ff !important;
}
[dir] .btn-secondary:focus, [dir] .btn-secondary:active, [dir] .btn-secondary.active {
    background-color: #006ef5 !important;
}
.cardHie1 {
  border-radius: 20px;
  border: 2px solid rgb(241, 241, 241);
  background-color: rgb(255, 255, 255) !important;
  padding: 5%;
}
.label {
  color: #e71c1c;
  font-size: 0.857rem;
}
</style>
